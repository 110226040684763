import axios from 'axios';

const api = axios.create({
  baseURL: 'https://api1.vacina.saude.araguaina.to.gov.br',
  headers: {
    Accept: 'application/json',
  },
});

export const authApi = axios.create({
  baseURL: 'https://api2.vacina.saude.araguaina.to.gov.br',
  headers: {
    Accept: 'application/json',
  },
});

api.interceptors.request.use(async (config) => {
  const token = localStorage.getItem('@VACINES_TOKENS');
  const headers = { ...config.headers };

  if (token) headers.Authorization = `Bearer ${token}`;
  return { ...config, headers };
});

export default api;
