import {
  VACINOMETER_REQUEST,
  VACINOMETER_SUCCESS,
  VACINOMETER_ERROR,
} from 'actions/vacinometer';

const initialState = {
  loading: false,
  data: {
    first_dose: 0,
    seccont_dose: 0,
    received: 0,
    applied: 0,
    types: [],
  },
};

const auth = (state = initialState, action) => {
  switch (action.type) {
    case VACINOMETER_REQUEST:
      return {
        ...state,
        loading: action.payload.loading,
      };
    case VACINOMETER_SUCCESS:
      return {
        ...state,
        data: action.payload.data,
        loading: action.payload.loading,
      };
    case VACINOMETER_ERROR:
      return {
        ...state,
        loading: action.payload.loading,
      };
    default:
      return state;
  }
};

export default auth;
