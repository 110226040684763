import {
  DOWNLOADS_REQUEST,
  DOWNLOADS_SUCCES,
  DOWNLOADS_ERROR,
  UPLOAD_FILE_SUCCES,
} from '../actions/anexos';

const initialState = {
  loading: false,
  data: [],
  types: [],
  progress: 0,
};

const anexos = (state = initialState, action) => {
  switch (action.type) {
    case DOWNLOADS_REQUEST:
      return {
        ...state,
        loading: action.payload.loading,
      };
    case DOWNLOADS_SUCCES:
      return {
        ...state,
        data: action.payload.data,
        types: action.payload.types,
        loading: action.payload.loading,
      };
    case DOWNLOADS_ERROR:
      return {
        ...state,
        loading: action.payload.loading,
      };
    case UPLOAD_FILE_SUCCES:
      return {
        ...state,
        progress: action.payload.progress,
      };
    default:
      return state;
  }
};

export default anexos;
