import { toast } from 'react-toastify';
import api from 'services';

export const SCHEDULE_PATIENT_REQUEST = 'SCHEDULE_PATIENT_REQUEST';
export const SCHEDULE_PATIENT_SUCESS = 'SCHEDULE_PATIENT_SUCESS';

export const getScheduleList = () => async (dispatch) => {
  dispatch({
    type: SCHEDULE_PATIENT_REQUEST,
    payload: {
      loading: true,
    },
  });

  const getTime = (date) => (date ? new Date(date).getTime() : '');

  api
    .get('schedulePatient')
    .then(({ data }) => {
      const sorted = data
        .sort((a, b) => getTime(a.date) - getTime(b.date))
        .reverse();

      dispatch({
        type: SCHEDULE_PATIENT_SUCESS,
        payload: {
          data: sorted,
          loading: false,
        },
      });
    })
    .catch(() => toast('Erro a carregar', { type: 'error' }));
};

export const scheduleCreate = (data, refresh) => () => {
  api
    .post('schedulePatient', data)
    .then(() => {
      toast('Informação inserida', { type: 'success' });
      refresh('');
    })
    .catch(() => {
      toast('Erro gravar dado', { type: 'error' });
    });
};

export const updateSchedule = (id, refresh) => () => {
  api
    .put(`schedulePatient/${id}`, { isVaccinated: 't' })
    .then(() => {
      toast('Atendimento realizado', { type: 'success' });
      refresh();
    })
    .catch(() => {
      toast('Erro ao atualizar', { type: 'error' });
    });
};

export const deleteSchedule = (id, refresh) => () => {
  api
    .delete(`schedulePatient/${id}`)
    .then(() => {
      toast('Dado deletado', { type: 'success' });
      refresh();
    })
    .catch(() => {
      toast('Erro ao deletar dado', { type: 'error' });
    });
};
