import { toast } from 'react-toastify';
import api from 'services';

export const IMAGES_REQUEST = 'IMAGES_REQUEST';
export const IMAGES_SUCCES = 'IMAGES_SUCCES';
export const IMAGES_ERROR = 'IMAGES_ERROR';
export const UPLOAD_IMAGE_SUCCES = 'UPLOAD_IMAGE_SUCCES';

export const getImagesList = () => async (dispatch) => {
  dispatch({
    type: IMAGES_REQUEST,
    payload: {
      loading: true,
    },
  });

  try {
    const { data: images } = await api.get('images');
    const { data: banner } = await api.get('banners');

    dispatch({
      type: IMAGES_SUCCES,
      payload: {
        data: {
          images,
          banner: banner[0],
        },
        errorMessage: false,
      },
    });
  } catch (e) {
    dispatch({
      type: IMAGES_ERROR,
      payload: {
        data: false,
        loading: false,
      },
    });

    toast('Erro ao carregar imagens');
  }
};

export const deleteImages = (id) => {
  api
    .delete(`images/${id}`)
    .then(() => {
      toast('Imagem deletada', { type: 'success' });
    })
    .catch(() => {
      toast('Erro ao deletar Imagem', { type: 'error' });
    });
};

export const uploadImage = (file, refresh) => (dispatch) => {
  const formData = new FormData();
  formData.append('image', file);

  api
    .post('/images', formData, {
      headers: { 'Content-Type': 'multipart/form-data' },
      onUploadProgress: ({ loaded, total }) => {
        dispatch({
          type: UPLOAD_IMAGE_SUCCES,
          payload: {
            progress: Number(Math.round((loaded * 100) / total)),
          },
        });
      },
    })
    .then(() => {
      setTimeout(
        () =>
          dispatch({
            type: UPLOAD_IMAGE_SUCCES,
            payload: { progress: 0 },
          }),
        10000,
      );
      refresh();
      toast('Imagem carregada', { type: 'success' });
    })
    .catch(() => {
      toast('Error ao carregar imgem', { type: 'error' });
    });
};

export const updateBanner = (id, file, refresh) => (dispatch) => {
  const formData = new FormData();
  formData.append('image', file);

  api
    .put(`/banners/${id}`, formData, {
      headers: { 'Content-Type': 'multipart/form-data' },
      onUploadProgress: ({ loaded, total }) => {
        dispatch({
          type: UPLOAD_IMAGE_SUCCES,
          payload: {
            progress: Number(Math.round((loaded * 100) / total)),
          },
        });
      },
    })
    .then(() => {
      setTimeout(
        () =>
          dispatch({
            type: UPLOAD_IMAGE_SUCCES,
            payload: { progress: 0 },
          }),
        10000,
      );
      refresh();
      toast('Imagem carregada', { type: 'success' });
    })
    .catch(() => {
      toast('Error ao carregar imgem', { type: 'error' });
    });
};
