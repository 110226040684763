import { PUBLIC_LIST_REQUEST, PUBLIC_LIST_SUCESS } from '../actions/publico';

const initialState = {
  data: [],
  loading: false,
};

const received = (state = initialState, action) => {
  switch (action.type) {
    case PUBLIC_LIST_REQUEST:
      return {
        ...state,
        data: action.payload.data,
        loading: action.payload.loading,
      };
    case PUBLIC_LIST_SUCESS:
      return {
        ...state,
        data: action.payload.data,
        loading: action.payload.loading,
      };
    default:
      return state;
  }
};

export default received;
