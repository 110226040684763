import { toast } from 'react-toastify';
import api from 'services';

export const PUBLIC_LIST_REQUEST = 'PUBLIC_LIST_REQUEST';
export const PUBLIC_LIST_SUCESS = 'PUBLIC_LIST_SUCESS';

export const getPublicoList = () => (dispatch) => {
  dispatch({
    type: PUBLIC_LIST_REQUEST,
    payload: {
      loading: true,
    },
  });

  api
    .get('topic')
    .then(({ data }) => {
      dispatch({
        type: PUBLIC_LIST_SUCESS,
        payload: {
          data,
          loading: false,
        },
      });
    })
    .catch(() => {
      toast('Erro a carregar', { type: 'error' });
    });
};

export const publicoCreate = (data, refresh) => () => {
  api
    .post('topic', data)
    .then(() => {
      toast('Informação inserida', { type: 'success' });
      refresh('');
    })
    .catch(() => {
      toast('Error gravar dado', { type: 'error' });
    });
};

export const publicoUpdate = ({ id, description }, refresh) => () => {
  api
    .put(`topic/${id}`, { description })
    .then(() => {
      toast('Dado atualizado', { type: 'success' });
      refresh('');
    })
    .catch(() => {
      toast('Erro ao atualizar dado', { type: 'error' });
    });
};

export const deletePublico = (id, refresh) => () => {
  api
    .delete(`topic/${id}`)
    .then(() => {
      toast('Dado deletado', { type: 'success' });
      refresh();
    })
    .catch(() => {
      toast('Erro ao deletar dado', { type: 'error' });
    });
};
