import { toast } from 'react-toastify';
import api from 'services';

export const DOWNLOADS_REQUEST = 'DOWNLOADS_REQUEST';
export const DOWNLOADS_SUCCES = 'DOWNLOADS_SUCCES';
export const DOWNLOADS_ERROR = 'DOWNLOADS_ERROR';
export const UPLOAD_FILE_SUCCES = 'UPLOAD_FILE_SUCCES';

export const getDownloadsList = () => async (dispatch) => {
  dispatch({
    type: DOWNLOADS_REQUEST,
    payload: {
      loading: true,
    },
  });

  const { data: types } = await api.get('categoriesFiles');

  api
    .get('files')
    .then(({ data }) => {
      dispatch({
        type: DOWNLOADS_SUCCES,
        payload: {
          data,
          types,
          errorMessage: false,
        },
      });
    })
    .catch(() => {
      dispatch({
        type: DOWNLOADS_ERROR,
        payload: {
          data: false,
          loading: false,
        },
      });
    });
};

export const deleteFile = (id, getData) => () => {
  api
    .delete(`files/${id}`)
    .then(() => {
      toast('Arquivo deletado', { type: 'success' });
      getData();
    })
    .catch(() => {
      toast('Erro ao deletar Arquivo', { type: 'error' });
    });
};

export const uploadFile = (data, refresh) => (dispatch) => {
  const formData = new FormData();
  formData.append('name', data.name);
  formData.append('category_file_id', data.category_file_id);
  formData.append('files', data.file);

  api
    .post('/files', formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
      onUploadProgress: (progressEvent) => {
        dispatch({
          type: UPLOAD_FILE_SUCCES,
          payload: {
            progress: Number(
              Math.round((progressEvent.loaded * 100) / progressEvent.total),
            ),
          },
        });
      },
    })
    .then(() => {
      setTimeout(
        () =>
          dispatch({
            type: UPLOAD_FILE_SUCCES,
            payload: {
              progress: 0,
            },
          }),
        10000,
      );
      refresh();
      toast('Arquivo carregado', { type: 'success' });
    })
    .catch(() => {
      toast('Error ao carregar arquivo', { type: 'error' });
    });
};
