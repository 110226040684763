import { RECEIVED_LIST_REQUEST, RECEIVED_LIST_SUCESS } from '../actions/doses';

const initialState = {
  data: [],
  loading: false,
};

const received = (state = initialState, action) => {
  switch (action.type) {
    case RECEIVED_LIST_REQUEST:
      return {
        ...state,
        data: action.payload.data,
        loading: action.payload.loading,
      };
    case RECEIVED_LIST_SUCESS:
      return {
        ...state,
        data: action.payload.data,
        loading: action.payload.loading,
      };
    default:
      return state;
  }
};

export default received;
