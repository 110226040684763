import { toast } from 'react-toastify';
import api from 'services';

export const VACINOMETER_REQUEST = 'VACINOMETER_REQUEST';
export const VACINOMETER_SUCCESS = 'VACINOMETER_SUCCESS';
export const VACINOMETER_ERROR = 'VACINOMETER_ERROR';
const VACINOMETER_UPDATE_REQUEST = 'VACINOMETER_UPDATE_REQUEST';
const VACINOMETER_UPDATE_SUCCESS = 'VACINOMETER_UPDATE_SUCCESS';
const VACINOMETER_UPDATE_ERROR = 'VACINOMETER_UPDATE_ERROR';

export const getData = () => async (dispatch) => {
  dispatch({
    type: VACINOMETER_REQUEST,
    payload: {
      loading: true,
    },
  });

  try {
    const { data: applied } = await api.get('/applied');
    const { data: types } = await api.get('/types');

    // let first_dose = 0;
    // let seccont_dose = 0;
    // let received = 0;

    // types.rows.forEach((type) => {
    //   first_dose += type.first_dose_1;
    //   seccont_dose += type.seccont_dose_1;
    // });

    // receiveds.forEach((rec) => {
    //   received += rec.quantid;
    // });

    dispatch({
      type: VACINOMETER_SUCCESS,
      payload: {
        loading: false,
        data: {
          types: types.rows || [],
          ...applied[0],
        },
      },
    });
  } catch (error) {
    dispatch({
      type: VACINOMETER_ERROR,
      payload: {
        loading: false,
      },
    });

    toast('Erro ao carregar dados', { type: 'error' });
  }
};

export const updateData = (
  { id, first_dose_1, seccont_dose_1, class_vaccine },
  refresh,
) => (dispatch) => {
  dispatch({
    type: VACINOMETER_UPDATE_REQUEST,
    payload: {
      loading: true,
    },
  });

  api
    .put(`/types/${id}`, { first_dose_1, seccont_dose_1, class_vaccine })
    .then(() => {
      dispatch({
        type: VACINOMETER_UPDATE_SUCCESS,
        payload: {
          loading: false,
        },
      });

      toast('Dados atualizados', { type: 'success' });
      refresh();
    })
    .catch(() => {
      dispatch({
        type: VACINOMETER_UPDATE_ERROR,
        payload: {
          loading: false,
        },
      });
      toast('Erro ao atualizar dados', { type: 'error' });
    });
};

export const updateApplied = (
  {
    id,
    received,
    applied,
    unique_dose,
    first_dose,
    seccont_dose,
    booster_dose,
    first_pediatric_dose,
    second_pediatric_dose,
  },
  refresh,
) => (dispatch) => {
  dispatch({
    type: VACINOMETER_UPDATE_REQUEST,
    payload: {
      loading: true,
    },
  });

  api
    .put(`/applied/${id}`, {
      received,
      applied,
      unique_dose,
      first_dose,
      seccont_dose,
      booster_dose,
      first_pediatric_dose,
      second_pediatric_dose,
    })
    .then(() => {
      dispatch({
        type: VACINOMETER_UPDATE_SUCCESS,
        payload: {
          loading: false,
        },
      });

      toast('Dados atualizados', { type: 'success' });
      refresh();
    })
    .catch(() => {
      dispatch({
        type: VACINOMETER_UPDATE_ERROR,
        payload: {
          loading: false,
        },
      });
      toast('Erro ao atualizar dados', { type: 'error' });
    });
};

export const createData = (input, refresh) => () => {
  api
    .post('types', input)
    .then(() => {
      toast('Dado inserido', { type: 'success' });
      refresh();
    })
    .catch(() => {
      toast('Erro inserir dados', { type: 'error' });
    });
};

export const deleteData = (id, refresh) => (dispatch) => {
  dispatch({
    type: VACINOMETER_UPDATE_REQUEST,
    payload: {
      loading: true,
    },
  });

  api
    .delete(`/types/${id}`)
    .then(() => {
      toast('Dado deletado', { type: 'success' });
      refresh();
    })
    .catch(() => {
      toast('Erro ao deletar dado', { type: 'error' });
    });
};
