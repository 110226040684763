import React, { Suspense } from 'react';
import { Router, Switch, Route } from 'react-router-dom';

import history from './history';
import Private from './private';

const DefaultLayout = React.lazy(() => import('../components/DefaultLayout'));

const SignIn = React.lazy(() => import('../pages/SignIn'));
const DosesRecebidas = React.lazy(() => import('../pages/DosesRecebidas'));
const Programacao = React.lazy(() => import('../pages/Programacao'));
const Agendamento = React.lazy(() => import('../pages/Agendamento'));
const Horarios = React.lazy(() => import('../pages/Horarios'));
const Galeria = React.lazy(() => import('../pages/Galeria'));
const Anexos = React.lazy(() => import('../pages/Anexos'));
const Agenda = React.lazy(() => import('../pages/Agenda'));
const Publico = React.lazy(() => import('../pages/Publico'));
const Vacinometro = React.lazy(() => import('../pages/Vacinometro'));
const User = React.lazy(() => import('../pages/User'));

const loading = () => (
  <div className="animated fadeIn pt-3 text-center">Carregando...</div>
);

export const routes = [
  {
    path: '/doses',
    name: 'Doses',
    component: DosesRecebidas,
  },
  {
    path: '/vacinometro',
    name: 'Vacinômetro',
    component: Vacinometro,
  },
  {
    path: '/horarios',
    name: 'horarios',
    component: Horarios,
  },
  {
    path: '/programacao',
    name: 'Programação Semanal',
    component: Programacao,
  },
  {
    path: '/agendamento',
    name: 'Agendamento',
    component: Agendamento,
  },
  {
    path: '/galeria',
    name: 'Galeria',
    component: Galeria,
  },
  {
    path: '/anexos',
    name: 'Anexos',
    component: Anexos,
  },
  {
    path: '/agenda',
    name: 'Agenda',
    component: Agenda,
  },
  {
    path: '/members',
    name: 'Usuarios',
    component: User,
  },
  {
    path: '/publico',
    name: 'Usuarios',
    component: Publico,
  },
];

const Routes = () => (
  <Router history={history}>
    <Suspense fallback={loading()}>
      <Switch>
        {routes.map((route) => (
          <Private
            exact
            path={route.path}
            component={(props) => <DefaultLayout {...props} />}
          />
        ))}
        <Route path="/" component={(props) => <SignIn {...props} />} />
      </Switch>
    </Suspense>
  </Router>
);

export default Routes;
