import { toast } from 'react-toastify';
import api from 'services';

export const SCHEDULE_LIST_REQUEST = 'SCHEDULE_LIST_REQUEST';
export const SCHEDULE_LIST_SUCESS = 'SCHEDULE_LIST_SUCESS';

export const getScheduleList = () => async (dispatch) => {
  dispatch({
    type: SCHEDULE_LIST_REQUEST,
    payload: {
      loading: true,
    },
  });

  const getTime = (date) => {
    if (date) {
      return new Date(date).getTime();
    }

    return '';
  };

  const { data: programation } = await api.get('programation');

  api
    .get('schedule')
    .then(({ data }) => {
      const sorted = data
        .sort((a, b) => getTime(a.created_at) - getTime(b.created_at))
        .reverse();

      dispatch({
        type: SCHEDULE_LIST_SUCESS,
        payload: {
          data: sorted,
          loading: false,
          programation: programation[0],
        },
      });
    })
    .catch(() => {
      toast('Erro a carregar', { type: 'error' });
    });
};

export const scheduleCreate = (data, refresh) => () => {
  api
    .post('schedule', data)
    .then(() => {
      toast('Informação inserida', { type: 'success' });
      refresh('');
    })
    .catch(() => {
      toast('Erro gravar dado', { type: 'error' });
    });
};

export const scheduledUpdate = (
  { id, local, publico, horario },
  refresh,
) => () => {
  api
    .put(`schedule/${id}`, { local, publico, horario })
    .then(() => {
      toast('Dado atualizado', { type: 'success' });
      refresh('');
    })
    .catch(() => {
      toast('Erro ao atualizar dado', { type: 'error' });
    });
};

export const periodedUpdate = ({ id, de, ate }, refresh) => () => {
  api
    .put(`programation/${id}`, { de, ate })
    .then(() => {
      toast('Dado atualizado', { type: 'success' });
      refresh('');
    })
    .catch(() => {
      toast('Erro ao atualizar dado', { type: 'error' });
    });
};

export const deleteSchedule = (id, refresh) => () => {
  api
    .delete(`schedule/${id}`)
    .then(() => {
      toast('Dado deletado', { type: 'success' });
      refresh();
    })
    .catch(() => {
      toast('Erro ao deletar dado', { type: 'error' });
    });
};
