import {
  SCHEDULE_PATIENT_REQUEST,
  SCHEDULE_PATIENT_SUCESS,
} from '../actions/agendamento';

const initialState = {
  data: [],
  loading: false,
};

const received = (state = initialState, action) => {
  switch (action.type) {
    case SCHEDULE_PATIENT_REQUEST:
      return {
        ...state,
        loading: action.payload.loading,
      };
    case SCHEDULE_PATIENT_SUCESS:
      return {
        ...state,
        data: action.payload.data,
        loading: action.payload.loading,
      };
    default:
      return state;
  }
};

export default received;
