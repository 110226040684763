import { combineReducers } from 'redux';
import { reducer as formReducer } from 'redux-form';

import received from './received';
import programacao from './programacao';
import horarios from './horarios';
import agendamento from './agendamento';
import galeria from './galeria';
import auth from './auth';
import vacinometer from './vacinometer';
import anexos from './anexos';
import publico from './publico';

export default combineReducers({
  received,
  programacao,
  galeria,
  auth,
  vacinometer,
  anexos,
  horarios,
  agendamento,
  publico,
  form: formReducer,
});
