import { authApi } from 'services/index';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import history from 'routes/history';

export const LOGIN_REQUEST = 'LOGIN_REQUEST';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_ERROR = 'LOGIN_ERROR';

export const signin = (info, setUser, setOpen) => (dispatch) => {
  dispatch({
    type: LOGIN_REQUEST,
    payload: {
      loading: true,
    },
  });

  authApi
    .post('/auth', info)
    .then(({ data }) => {
      const { id, token, name, message, set_passowd, type } = data;

      if (set_passowd === 1) {
        setUser({ id, ...info });
        setOpen(true);
      } else if (message) {
        toast(message, { type: 'error' });
        dispatch({
          type: LOGIN_ERROR,
          payload: {
            loading: false,
          },
        });
      } else {
        dispatch({
          type: LOGIN_SUCCESS,
          payload: {
            loading: false,
            signedIn: true,
          },
        });

        localStorage.setItem('@VACINES_TOKENS', token);
        localStorage.setItem('@VACINES_NAME', name);
        if (type === 'root') {
          localStorage.setItem('@VACINES_TYPE', type);
          history.push('/agendamento');
        } else {
          history.push('/vacinometro');
        }
      }
    })
    .catch(() => {
      dispatch({
        type: LOGIN_ERROR,
        payload: {
          loading: false,
        },
      });
    });
};

export const updatePassword = (user, data) => (dispatch) => {
  const { doc_cpf, id } = user;
  const { password } = data;

  dispatch({
    type: LOGIN_REQUEST,
    payload: {
      loading: true,
    },
  });

  authApi
    .put(`/users/${id}`, { password, set_passowd: 0 })
    .then(() => {
      toast('Senha alterada com sucesso', { type: 'success' });
      dispatch(signin({ doc_cpf, password }));
    })
    .catch(() => {
      dispatch({
        type: LOGIN_ERROR,
        payload: {
          loading: false,
        },
      });
      toast('Erro ao alterar a senha', { type: 'error' });
    });
};

export const logout = () => {
  localStorage.removeItem('@VACINES_TOKENS');
  localStorage.removeItem('@VACINES_NAME');
  localStorage.removeItem('@VACINES_TYPE');
  history.push('/');
};
