import {
  IMAGES_REQUEST,
  IMAGES_SUCCES,
  IMAGES_ERROR,
  UPLOAD_IMAGE_SUCCES,
} from '../actions/galeria';

const initialState = {
  loading: false,
  data: {
    images: [],
    banner: {},
  },
  progress: 0,
};

const images = (state = initialState, action) => {
  switch (action.type) {
    case IMAGES_REQUEST:
      return {
        ...state,
        loading: action.payload.loading,
      };
    case IMAGES_SUCCES:
      return {
        ...state,
        data: action.payload.data,
        loading: action.payload.loading,
      };
    case IMAGES_ERROR:
      return {
        ...state,
        loading: action.payload.loading,
      };
    case UPLOAD_IMAGE_SUCCES:
      return {
        ...state,
        progress: action.payload.progress,
      };
    default:
      return state;
  }
};

export default images;
