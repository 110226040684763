import React from 'react';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import './App.scss';
import Routes from './routes';

const App = () => (
  <>
    <Routes />
    <ToastContainer />
  </>
);

export default App;
