import { toast } from 'react-toastify';
import api from 'services';

export const RECEIVED_LIST_REQUEST = 'RECEIVED_LIST_REQUEST';
export const RECEIVED_LIST_SUCESS = 'RECEIVED_LIST_SUCESS';

export const getReceivedList = () => (dispatch) => {
  dispatch({
    type: RECEIVED_LIST_REQUEST,
    payload: {
      loading: true,
    },
  });

  const getTime = (date) => {
    if (date) {
      return new Date(date).getTime();
    }

    return '';
  };

  api
    .get('received')
    .then(({ data }) => {
      const sorted = data
        .sort((a, b) => getTime(a.created_at) - getTime(b.created_at))
        .reverse();

      dispatch({
        type: RECEIVED_LIST_SUCESS,
        payload: {
          data: sorted,
          loading: false,
        },
      });
    })
    .catch(() => {
      toast('Erro a carregar', { type: 'error' });
    });
};

export const receivedCreate = (data, refresh) => () => {
  api
    .post('received', data)
    .then(() => {
      toast('Informação inserida', { type: 'success' });
      refresh('');
    })
    .catch(() => {
      toast('Error gravar dado', { type: 'error' });
    });
};

export const receivedUpdate = (
  { id, lote, quantid, vaccine, date_received },
  refresh,
) => () => {
  api
    .put(`received/${id}`, { lote, quantid, vaccine, date_received })
    .then(() => {
      toast('Dado atualizado', { type: 'success' });
      refresh('');
    })
    .catch(() => {
      toast('Erro ao atualizar dado', { type: 'error' });
    });
};

export const deleteReceived = (id, refresh) => () => {
  api
    .delete(`received/${id}`)
    .then(() => {
      toast('Dado deletado', { type: 'success' });
      refresh();
    })
    .catch(() => {
      toast('Erro ao deletar dado', { type: 'error' });
    });
};
